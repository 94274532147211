import React from 'react';
import ReactDOM from 'react-dom';

export default class MoveTransactionsModal extends React.Component {
  constructor(props) {
    super(props);
    // recordType can be either entity or account
    this.state = {
      recordType: props.recordType,
      selectOptions: props.selectOptions,
      id: props.id,
    };

    if (props.recordType == 'entity') {
      this.recordDisplayText = 'person';
    } else if (props.recordType == 'account') {
      this.recordDisplayText = 'account';
    } else if (props.recordType == 'item') {
      this.recordDisplayText = 'item';
    } else {
      throw 'unknown record type';
    }

    this._handleSubmit = this._handleSubmit.bind(this);
  }

  componentDidMount() {
    $('#move-transactions-select').select2();
  }

  _handleSubmit() {
    const newId = $('#move-transactions-select').val();
    const that = this;

    confirm(
      'Are you sure you want to move all transactions? This action cannot be undone.',
      function (ok) {
        if (ok) {
          const newId = $('#move-transactions-select').val();
          let url;

          if (that.state.recordType == 'entity') {
            url = '/entities/move_transactions';
          } else if (that.state.recordType == 'account') {
            url = '/accounts/move_transactions';
          } else if (that.state.recordType == 'item') {
            url = '/items/move_transactions';
          } else {
            throw 'unknown record type';
          }

          startLoadingSpinner('mdc-dialog-move-transactions-section');
          $.ajax({
            url: url,
            type: 'POST',
            data: {
              id: that.state.id,
              new_id: newId,
            },
            dataType: 'JSON',
            success: (data) => {
              stopLoadingSpinner('mdc-dialog-move-transactions-section');
              if (data.errors) {
                alert(data.errors);
              } else {
                showSnackbarMessage(
                  'Transactions moved successfully',
                  'pm-main-section'
                );
                ReactDOM.unmountComponentAtNode(
                  document.getElementById('mdc-dialog-move-transactions')
                    .parentNode
                );
              }
            },
          });
        }
      }
    );
  }

  render() {
    const selectOpts = this.state.selectOptions.map((optArr, idx) => {
      return (
        <option className="mdc-list-item" value={optArr[1]} key={idx}>
          {optArr[0]}
        </option>
      );
    });

    return (
      <aside
        id="mdc-dialog-move-transactions"
        className="mdc-dialog mdc-dialog--open"
        role="alertdialog"
        aria-hidden="true"
        aria-labelledby="mdc-dialog-default-label"
        aria-describedby="mdc-dialog-default-description"
      >
        <div className="mdc-dialog__surface">
          <header className="mdc-dialog__header pm-bg-color--blue1">
            <h2
              id="mdc-dialog-default-label"
              className="mdc-dialog__header__title"
            >
              Move Transactions
            </h2>
          </header>
          <section
            id="mdc-dialog-move-transactions-section"
            className="mdc-dialog__body mdc-dialog__body--scrollable"
          >
            <div className="pm-select">
              <label htmlFor="move-transactions-select">
                Move transactions to
              </label>
              <select
                id="move-transactions-select"
                className="pm-default-select2-menu mdc-list mdc-menu__items"
              >
                {selectOpts}
              </select>
            </div>
          </section>
          <footer className="mdc-dialog__footer">
            <button
              type="button"
              className="mdc-button pm-text-color--grey5 mdc-dialog__footer__button mdc-dialog__footer__button--cancelPM"
            >
              Cancel
            </button>
            <button
              type="button"
              className="mdc-button pm-text-color--blue1 mdc-dialog__footer__button"
              onClick={this._handleSubmit}
            >
              Save
            </button>
          </footer>
        </div>
        <div className="mdc-dialog__backdrop"></div>
      </aside>
    );
  }
}
